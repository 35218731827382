<template>
  <table>
    <tr>
      <td></td>
      <td class="font-bold">per Candle</td>
      <td></td>
      <td class="font-bold">for Batch</td>
      <td colspan="2"></td>
    </tr>

    <tr>
      <td>Fragrance:</td>
      <td class="oilwax-border text-right">
        <span :class="{'opacity-0': !value.fragrance}">
          {{ roundNumber(value.fragrance, 2, 2) }} g
        </span>
      </td>
      <td><span class="mx-1"></span></td>
      <td class="oilwax-border text-right">
        <span :class="{'opacity-0': !value.fragrance || !value.count}">
          {{ roundNumber(
              value.fragrance
              * value.count
              , 2, 2)
          }} g
        </span>
      </td>
      <td class="text-center"><span class="mx-1">or</span></td>
      <td class="oilwax-border text-right">
        <span :class="{'opacity-0': !value.fragrance || !value.count}">
          {{ roundNumber(
              value.fragrance
              * value.count
              / 1000
              , 3, 3)
          }} kg
        </span>
      </td>
    </tr>

    <tr>
      <td>Wax:</td>
      <td class="oilwax-border text-right">
        <span :class="{'opacity-0': !value.wax}">
          {{ roundNumber(value.wax, 2, 2) }} g
        </span>
      </td>
      <td><span class="mx-1"></span></td>
      <td class="oilwax-border text-right">
        <span :class="{'opacity-0': !value.wax || !value.count}">
          {{ roundNumber(
              value.wax
              * value.count
              , 2, 2)
          }} g
        </span>
      </td>
      <td class="text-center"><span class="mx-1">or</span></td>
      <td class="oilwax-border text-right">
        <span :class="{'opacity-0': !value.wax || !value.count}">
          {{ roundNumber(
              value.wax
              * value.count
              / 1000
              , 3, 3)
          }} kg
        </span>
      </td>
    </tr>

    <tr>
      <td>Dye:</td>
      <td class="oilwax-border text-right">
        <span :class="{'opacity-0': !value.dye}">
          {{ roundNumber(value.dye, 2, 2) }} g
        </span>
      </td>
      <td><span class="mx-1"></span></td>
      <td class="oilwax-border text-right">
        <span :class="{'opacity-0': !value.dye || !value.count}">
          {{ roundNumber(
              value.dye
              * value.count
              , 2, 2)
          }} g
        </span>
      </td>
      <td class="text-center"><span class="mx-1">or</span></td>
      <td class="oilwax-border text-right">
        <span :class="{'opacity-0': !value.dye || !value.count}">
          {{ roundNumber(
              value.dye
              * value.count
              / 1000
              , 3, 3)
          }} kg
        </span>
      </td>
    </tr>

    <tr>
      <td>Additive 1:</td>
      <td class="oilwax-border text-right">
        <span :class="{'opacity-0': !value.additive1}">
          {{ roundNumber(value.additive1, 2, 2) }} g
        </span>
      </td>
      <td><span class="mx-1"></span></td>
      <td class="oilwax-border text-right">
        <span :class="{'opacity-0': !value.additive1 || !value.count}">
          {{ roundNumber(
            value.additive1
            * value.count
            , 2, 2)
          }} g
        </span>
      </td>
      <td class="text-center"><span class="mx-1">or</span></td>
      <td class="oilwax-border text-right">
        <span :class="{'opacity-0': !value.additive1 || !value.count}">
          {{ roundNumber(
            value.additive1
            * value.count
            / 1000
            , 3, 3)
          }} kg
        </span>
      </td>
    </tr>

    <tr>
      <td>Additive 2:</td>
      <td class="oilwax-border text-right">
        <span :class="{'opacity-0': !value.additive2}">
          {{ roundNumber(value.additive2, 2, 2) }} g
        </span>
      </td>
      <td><span class="mx-1"></span></td>
      <td class="oilwax-border text-right">
        <span :class="{'opacity-0': !value.additive2 || !value.count}">
          {{ roundNumber(
            value.additive2
            * value.count
            , 2, 2)
          }} g
        </span>
      </td>
      <td class="text-center"><span class="mx-1">or</span></td>
      <td class="oilwax-border text-right">
        <span :class="{'opacity-0': !value.additive2 || !value.count}">
          {{ roundNumber(
            value.additive2
            * value.count
            / 1000
            , 3, 3)
          }} kg
        </span>
      </td>
    </tr>
    <tr>
      <td>
        <span class="font-bold">Total</span>
      </td>
      <td class="oilwax-border text-right font-bold">
        <span :class="{'opacity-0': !value.total}">
          {{ roundNumber(value.total, 2, 2) }} g
        </span>
      </td>
      <td></td>
      <td class="oilwax-border text-right font-bold">
        <span :class="{'opacity-0': !value.total || !value.count}">
          {{ roundNumber(value.total * value.count, 2, 2) }} g
        </span>
      </td>
      <td class="text-center"><span class="mx-1">or</span></td>
      <td class="oilwax-border text-right font-bold">
        <span :class="{'opacity-0': !value.total || !value.count}">
          {{ roundNumber(
            value.total
            * value.count
            / 1000
            , 3, 3)
          }} kg
        </span>
      </td>
    </tr>
  </table>
</template>

<script>
import { roundNumber } from '@/helpers';

export default {
  name: 'OptionTable',
  components: {},
  props: {
    value: Object,
  },
  data() {
    return {};
  },
  methods: {
    roundNumber(value, minimumFractionDigits, maximumFractionDigits) {
      return roundNumber(value, minimumFractionDigits, maximumFractionDigits);
    },
  },
};
</script>

<style lang="scss">
  .oilwax-border {
    width: 180px;
    border: 1px solid black;
    border-collapse: collapse;
    padding: 5px 8px;
  }
</style>
